<template>
  <div class="vx-row">
    <vx-card>
      <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
          <h4>Nouveau Client</h4>
          <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>
        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :key="$vs.rtl">
          <div class="vx-row px-6">
            <div class="vx-col md:w-1/3 w-full mt-5">
              <vs-select v-model="Civilite" class="w-full select-large mt-4" label="Civilité">
                <vs-select-item :key="index" :value="item.text" :text="item.value" v-for="(item,index) in civilites" class="w-full" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <vs-input label="Nom" :value="Nom" @change.native="Nom=$event.target.value" class="w-full mt-4" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <vs-input label="Prenoms" :value="Prenoms" @change.native="Prenoms=$event.target.value" class="w-full mt-4" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <vs-input label="Alias" :value="Alias" @change.native="Alias=$event.target.value" class="w-full mt-4" />
            </div>

            <div class="vx-col md:w-1/3 w-full mt-5">
              <vs-input label="Profession" :value="Profession" @change.native="Profession=$event.target.value" class="w-full mt-4" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
              <div class="mt-4">
                <label class="text-sm">Anniversaire</label>
                <datepicker :language="$vs.rtl ? langHe : langFr" format="dd MMMM yyyy" v-model="DateNaiss" class="w-full" ></datepicker>
              </div>
            </div>
          </div>
          <div class="vx-row px-6">
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-input label="Adresse" :value="adresse_client" @change.native="adresse_client=$event.target.value" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
                <vs-input type="email" label="Email" :success="email ? true : false" success-text="Mail valide" :value="email" @change.native="email=$event.target.value" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-select autocomplete label="Pays" class="w-full" v-model="pays_client" >
                <vs-select-item :key="index" :value="item.pays" :text="item.pays" v-for="(item, index) in     PaysData" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-select autocomplete label="Ville" class="w-full" v-model="ville" >
                <vs-select-item v-for="(item, index) in villeData" :key="index" :value="item.libelleVille" :text="item.libelleVille"/>
              </vs-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-select autocomplete label="Quartier" class="w-full" v-model="quartier" >
                <vs-select-item v-for="(item, index) in quartierData" :key="index" :value="item.libelleQtier" :text="item.libelleQtier"/>
              </vs-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-input label="Localisation" :value="localite" @change.native="localite=$event.target.value" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-input label="Fixe" :value="tel" @change.native="tel=$event.target.value" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-input label="Mobile" :value="mobile" @change.native="mobile=$event.target.value" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-select autocomplete label="Langue" class="w-full" v-model="Langue">
              <vs-select-item :key="index" :value="item.code" :text="item.langue" v-for="(item, index) in Languages" />
            </vs-select>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <div class="vx-row">
                <div class="vx-col">
                  <vs-checkbox label="Assure" v-model="Assure" class="w-full mt-1" >Assuré </vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox label="PasSMS" v-model="PasSMS" class="w-full mt-1" >Pas SMS </vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox label="PasMail" v-model="PasMail" class="w-full mt-1" >Pas Mail </vs-checkbox>
                </div>
                <div class="vx-col">
                  <vs-checkbox label="CarteFidelite" v-model="CarteFidelite" class="w-full mt-1" >Fidelité </vs-checkbox>
                </div>

                <!-- <div class="vx-col">
                  <vs-checkbox label="ChefFamille" v-model="ChefFamille" class="w-full mt-1" >Chef de famille</vs-checkbox>
                </div> -->
                <div class="vx-col">
                  <vs-checkbox label="DCD" v-model="DCD" class="w-full mt-1" >DCD </vs-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-row px-6">
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-input label="Remarque" :value="remarque" @change.native="remarque=$event.target.value" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
              <vs-input label="Activité extra" :value="Activitextra" @change.native="Activitextra=$event.target.value" class="w-full" />
            </div>
          </div>
        </component>

        <div class="flex flex-wrap items-center p-6 " slot="footer">
          <vs-button class="mr-6" @click="createClient">Enregister</vs-button>
          <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Annuler</vs-button>
        </div>
      </vs-sidebar>
      <div class="vx-row">
        <div class="vx-col w-full text-center">
          <h3>Questionnaire de l'examen de vue</h3>
        </div>
        <div class="vx-col md:w-1/3">
          <div class="my-4">
            <small class="date-label">Date de l'examen</small>
            <datepicker :language="$vs.rtl ? langHe : langFr" name="dateCommandeVerre" v-model="DateExamen"></datepicker>
          </div>
        </div>
        <div class="vx-col md:w-1/3">
          <vs-select label="Client" autocomplete class="selectExample w-full select-large mt-4" v-model="IdClient" @change="NouveauClient">
            <vs-select-item value="new_client" text="Nouveau" />
            <vs-divider/>
            <vs-select-item :key="index" :value="item._id" :text="`${item.Nom} ${item.Prenoms}`" v-for="(item,index) in clientsData" />
          </vs-select>
          <div v-if="IdClient">
            <p>Nom et prénoms: {{NomClient}} {{PrenomsClient}}</p>
            <p>Date de naissance: {{DateNaissanceClient | moment("calendar", "July 10 2011") }}</p>
            <p>Domicile: {{DomicileClient}}</p>
            <p>Contact: {{ContactClient}}</p>
            <p>Profession: {{ProfessionClient}}</p>
          </div>
        </div>
        <div class="vx-col md:w-1/3">
          <vs-select label="Porteur connecté" autocomplete class="selectExample w-full select-large mt-4" v-model="PorteurConnecter">
            <vs-select-item :value="true" text="Oui" />
            <vs-select-item :value="false" text="Nom" />
          </vs-select>
        </div>
        <div class="vx-col w-full">
          <vx-card title="Plaintes" :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/3">
                <vs-select label="Gêné par le soleil" autocomplete class="selectExample w-full select-large mt-4" v-model="GeneParLeSoleil">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Gêné par la lumière artificielle" autocomplete class="selectExample w-full select-large mt-4" v-model="GeneParLaLumiereArtificielle">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Vision floue" autocomplete class="selectExample w-full select-large mt-4" v-model="VisionFloue">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Vision de près difficile" autocomplete class="selectExample w-full select-large mt-4" v-model="VisionDePresDifficile">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Vision de loin difficile" autocomplete class="selectExample w-full select-large mt-4" v-model="VisionDeLoinDifficile">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Larmoiement" autocomplete class="selectExample w-full select-large mt-4" v-model="Larmoiement">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Démangeaison oculaire" autocomplete class="selectExample w-full select-large mt-4" v-model="DemangeaisonOculaire">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Cephalées" autocomplete class="selectExample w-full select-large mt-4" v-model="Cephalees">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Fatigue visuelle" autocomplete class="selectExample w-full select-large mt-4" v-model="FatigueVisuelle">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Diabétique" autocomplete class="selectExample w-full select-large mt-4" v-model="Diabetique">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Tension arterielle" autocomplete class="selectExample w-full select-large mt-4" v-model="TensionArterielle">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Ayant déjà reçu un choc à l'oeil" autocomplete class="selectExample w-full select-large mt-4" v-model="ChocAOeil">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Chirurgie réfractive" autocomplete class="selectExample w-full select-large mt-4" v-model="ChirurgieRefractive">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-select label="Rougeur" autocomplete class="selectExample w-full select-large mt-4" v-model="Rougeur">
                  <vs-select-item :value="true" text="Oui" />
                  <vs-select-item :value="false" text="Non" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/3">
                <vs-input label="Autre plaintes" :value="AutrePlainte" @change.native="AutrePlainte=$event.target.value" class="w-full mt-4" />
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col w-full mt-5">
          <vx-card title="Statut porteur" :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-checkbox label="Nouveau porteur" v-model="NouveauPorteur" class="w-full mt-4" >Nouveau porteur </vs-checkbox>
              </div>
              <div class="vx-col md:w-1/2">
                <vs-checkbox label="Ancien porteur" v-model="AncienPorteur" class="w-full mt-1" >Ancien porteur</vs-checkbox>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <div class="vx-row" v-if="NouveauPorteur">
                  <div class="vx-col md:w-1/2">
                    <img src="./droit.svg" alt="" srcset="">
                  </div>
                  <div class="vx-col md:w-1/2">
                    <img src="./Gauche.svg" alt="" srcset="">
                  </div>
                </div>
                <vs-table stripe v-if="NouveauPorteur">
                  <template slot="thead">
                    <vs-th>Vision</vs-th>
                    <vs-th>Axe</vs-th>
                    <vs-th>Type</vs-th>
                    <vs-th>Verre cylindrique</vs-th>
                    <vs-th>Verre sphérique</vs-th>
                    <vs-th>AV</vs-th>
                    <vs-th>Axe</vs-th>
                    <vs-th>Verre cylindrique</vs-th>
                    <vs-th>Verre sphérique</vs-th>
                    <vs-th>AV</vs-th>
                  </template>
                </vs-table>
              </div>
              <div class="vx-col md:w-1/2 mt-4" v-if="AncienPorteur">

              </div>

            </div>
          </vx-card>
        </div>
      </div>
    </vx-card>
  </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import { en, he, fr } from 'vuejs-datepicker/src/locale'
export default {
  components: { Datepicker},
  data () {
    return {
      DateExamen: null,
      IdClient: null,
      NomClient: '',
      PrenomsClient: '',
      DateNaissanceClient: '',
      DomicileClient: '',
      ContactClient: '',
      ProfessionClient: '',
      PorteurConnecter: null,
      GeneParLeSoleil: null,
      GeneParLaLumiereArtificielle: null,
      VisionFloue: null,
      VisionDePresDifficile: null,
      VisionDeLoinDifficile: null,
      Larmoiement: null,
      DemangeaisonOculaire: null,
      Cephalees: null,
      FatigueVisuelle: null,
      Diabetique: null,
      TensionArterielle: null,
      ChocAOeil: null,
      ChirurgieRefractive: null,
      Rougeur: null,
      AutrePlainte: null,
      NouveauPorteur: null,
      AncienPorteur: null,
      isSidebarActiveLocal: false,

      idOfficine: null,
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : null,

      civilites: [
        {
          text: 'Prof',
          value: 'Prof'
        },
        {
          text: 'Dr',
          value: 'Dr'
        },
        {
          text: 'M',
          value: 'M'
        },
        {
          text: 'Mme',
          value: 'Mme'
        },
        {
          text: 'Mlle',
          value: 'Mlle'
        },
        {
          text: 'Enft',
          value: 'Enft'
        }
      ],
      Civilite: '',
      Nom: '',
      Prenoms: '',
      Alias: '',
      Profession: '',
      DateNaiss: '',
      adresse_client: '',
      email: '',
      pays_client: '',
      ville: '',
      quartier: '',
      localite: '',
      tel: '',
      mobile_client: '',
      mobile:'',
      fax_client: '',
      cp: '',
      Activitextra: '',
      Langue: '',
      remarque: '',
      CarteFidelite: false,
      Assure: false,
      DCD: false,
      ChefFamille: false,
      PasSMS: false,
      PasMail: false,

      infosClient: {},

      langHe: he,
      langEn: en,
      langFr: fr,

      Languages: [
        {code: 'af', langue: 'Afrikaans'},
        {code: 'sq', langue: 'Albanian - shqip'},
        {code: 'am', langue: 'Amharic - አማርኛ'},
        {code: 'ar', langue: 'Arabic - العربية'},
        {code: 'an', langue: 'Aragonese - aragonés'},
        {code: 'hy', langue: 'Armenian - հայերեն'},
        {code: 'ast', langue: 'Asturian - asturianu'},
        {code: 'az', langue: 'Azerbaijani - azərbaycan dili'},
        {code: 'eu', langue: 'Basque - euskara'},
        {code: 'be', langue: 'Belarusian - беларуская'},
        {code: 'bn', langue: 'Bengali - বাংলা'},
        {code: 'bs', langue: 'Bosnian - bosanski'},
        {code: 'br', langue: 'Breton - brezhoneg'},
        {code: 'bg', langue: 'Bulgarian - български'},
        {code: 'ca', langue: 'Catalan - català'},
        {code: 'ckb', langue: 'Central Kurdish - کوردی (دەستنوسی عەرەبی)'},
        {code: 'zh', langue: 'Chinese - 中文'},
        {code: 'zh-HK', langue: 'Chinese (Hong Kong) - 中文（香港）'},
        {code: 'zh-CN', langue: 'Chinese (Simplified) - 中文（简体）'},
        {code: 'zh-TW', langue: 'Chinese (Traditional) - 中文（繁體）'},
        {code: 'co', langue: 'Corsican'},
        {code: 'hr', langue: 'Croatian - hrvatski'},
        {code: 'cs', langue: 'Czech - čeština'},
        {code: 'da', langue: 'Danish - dansk'},
        {code: 'nl', langue: 'Dutch - Nederlands'},
        {code: 'en', langue: 'English'},
        {code: 'en-AU', langue: 'English (Australia)'},
        {code: 'en-CA', langue: 'English (Canada)'},
        {code: 'en-IN', langue: 'English (India)'},
        {code: 'en-NZ', langue: 'English (New Zealand)'},
        {code: 'en-ZA', langue: 'English (South Africa)'},
        {code: 'en-GB', langue: 'English (United Kingdom)'},
        {code: 'en-US', langue: 'English (United States)'},
        {code: 'eo', langue: 'Esperanto - esperanto'},
        {code: 'et', langue: 'Estonian - eesti'},
        {code: 'fo', langue: 'Faroese - føroyskt'},
        {code: 'fil', langue: 'Filipino'},
        {code: 'fi', langue: 'Finnish - suomi'},
        {code: 'fr', langue: 'French - français'},
        {code: 'fr-CA', langue: 'French (Canada) - français (Canada)'},
        {code: 'fr-FR', langue: 'French (France) - français (France)'},
        {code: 'fr-CH', langue: 'French (Switzerland) - français (Suisse)'},
        {code: 'gl', langue: 'Galician - galego'},
        {code: 'ka', langue: 'Georgian - ქართული'},
        {code: 'de', langue: 'German - Deutsch'},
        {code: 'de-AT', langue: 'German (Austria) - Deutsch (Österreich)'},
        {code: 'de-DE', langue: 'German (Germany) - Deutsch (Deutschland)'},
        {code: 'de-LI', langue: 'German (Liechtenstein) - Deutsch (Liechtenstein)'},
        {code: 'de-CH', langue: 'German (Switzerland) - Deutsch (Schweiz)'},
        {code: 'el', langue: 'Greek - Ελληνικά'},
        {code: 'gn', langue: 'Guarani'},
        {code: 'gu', langue: 'Gujarati - ગુજરાતી'},
        {code: 'ha', langue: 'Hausa'},
        {code: 'haw', langue: 'Hawaiian - ʻŌlelo Hawaiʻi'},
        {code: 'he', langue: 'Hebrew - עברית'},
        {code: 'hi', langue: 'Hindi - हिन्दी'},
        {code: 'hu', langue: 'Hungarian - magyar'},
        {code: 'is', langue: 'Icelandic - íslenska'},
        {code: 'id', langue: 'Indonesian - Indonesia'},
        {code: 'ia', langue: 'Interlingua'},
        {code: 'ga', langue: 'Irish - Gaeilge'},
        {code: 'it', langue: 'Italian - italiano'},
        {code: 'it-IT', langue: 'Italian (Italy) - italiano (Italia)'},
        {code: 'it-CH', langue: 'Italian (Switzerland) - italiano (Svizzera)'},
        {code: 'ja', langue: 'Japanese - 日本語'},
        {code: 'kn', langue: 'Kannada - ಕನ್ನಡ'},
        {code: 'kk', langue: 'Kazakh - қазақ тілі'},
        {code: 'km', langue: 'Khmer - ខ្មែរ'},
        {code: 'ko', langue: 'Korean - 한국어'},
        {code: 'ku', langue: 'Kurdish - Kurdî'},
        {code: 'ky', langue: 'Kyrgyz - кыргызча'},
        {code: 'lo', langue: 'Lao - ລາວ'},
        {code: 'la', langue: 'Latin'},
        {code: 'lv', langue: 'Latvian - latviešu'},
        {code: 'ln', langue: 'Lingala - lingála'},
        {code: 'lt', langue: 'Lithuanian - lietuvių'},
        {code: 'mk', langue: 'Macedonian - македонски'},
        {code: 'ms', langue: 'Malay - Bahasa Melayu'},
        {code: 'ml', langue: 'Malayalam - മലയാളം'},
        {code: 'mt', langue: 'Maltese - Malti'},
        {code: 'mr', langue: 'Marathi - मराठी'},
        {code: 'mn', langue: 'Mongolian - монгол'},
        {code: 'ne', langue: 'Nepali - नेपाली'},
        {code: 'no', langue: 'Norwegian - norsk'},
        {code: 'nb', langue: 'Norwegian Bokmål - norsk bokmål'},
        {code: 'nn', langue: 'Norwegian Nynorsk - nynorsk'},
        {code: 'oc', langue: 'Occitan'},
        {code: 'or', langue: 'Oriya - ଓଡ଼ିଆ'},
        {code: 'om', langue: 'Oromo - Oromoo'},
        {code: 'ps', langue: 'Pashto - پښتو'},
        {code: 'fa', langue: 'Persian - فارسی'},
        {code: 'pl', langue: 'Polish - polski'},
        {code: 'pt', langue: 'Portuguese - português'},
        {code: 'pt-BR', langue: 'Portuguese (Brazil) - português (Brasil)'},
        {code: 'pt-PT', langue: 'Portuguese (Portugal) - português (Portugal)'},
        {code: 'pa', langue: 'Punjabi - ਪੰਜਾਬੀ'},
        {code: 'qu', langue: 'Quechua'},
        {code: 'ro', langue: 'Romanian - română'},
        {code: 'mo', langue: 'Romanian (Moldova) - română (Moldova)'},
        {code: 'rm', langue: 'Romansh - rumantsch'},
        {code: 'ru', langue: 'Russian - русский'},
        {code: 'gd', langue: 'Scottish Gaelic'},
        {code: 'sr', langue: 'Serbian - српски'},
        {code: 'sh', langue: 'Serbo-Croatian - Srpskohrvatski'},
        {code: 'sn', langue: 'Shona - chiShona'},
        {code: 'sd', langue: 'Sindhi'},
        {code: 'si', langue: 'Sinhala - සිංහල'},
        {code: 'sk', langue: 'Slovak - slovenčina'},
        {code: 'sl', langue: 'Slovenian - slovenščina'},
        {code: 'so', langue: 'Somali - Soomaali'},
        {code: 'st', langue: 'Southern Sotho'},
        {code: 'es', langue: 'Spanish - español'},
        {code: 'es-AR', langue: 'Spanish (Argentina) - español (Argentina)'},
        {code: 'es-419', langue: 'Spanish (Latin America) - español (Latinoamérica)'},
        {code: 'es-MX', langue: 'Spanish (Mexico) - español (México)'},
        {code: 'es-ES', langue: 'Spanish (Spain) - español (España)'},
        {code: 'es-US', langue: 'Spanish (United States) - español (Estados Unidos)'},
        {code: 'su', langue: 'Sundanese'},
        {code: 'sw', langue: 'Swahili - Kiswahili'},
        {code: 'sv', langue: 'Swedish - svenska'},
        {code: 'tg', langue: 'Tajik - тоҷикӣ'},
        {code: 'ta', langue: 'Tamil - தமிழ்'},
        {code: 'tt', langue: 'Tatar'},
        {code: 'te', langue: 'Telugu - తెలుగు'},
        {code: 'th', langue: 'Thai - ไทย'},
        {code: 'ti', langue: 'Tigrinya - ትግርኛ'},
        {code: 'to', langue: 'Tongan - lea fakatonga'},
        {code: 'tr', langue: 'Turkish - Türkçe'},
        {code: 'tk', langue: 'Turkmen'},
        {code: 'tw', langue: 'Twi'},
        {code: 'uk', langue: 'Ukrainian - українська'},
        {code: 'ur', langue: 'Urdu - اردو'},
        {code: 'ug', langue: 'Uyghur'},
        {code: 'uz', langue: 'Uzbek - o‘zbek'},
        {code: 'vi', langue: 'Vietnamese - Tiếng Việt'},
        {code: 'wa', langue: 'Walloon - wa'},
        {code: 'cy', langue: 'Welsh - Cymraeg'},
        {code: 'fy', langue: 'Western Frisian'},
        {code: 'xh', langue: 'Xhosa'},
        {code: 'yi', langue: 'Yiddish'},
        {code: 'yo', langue: 'Yoruba - Èdè Yorùbá'},
        {code: 'zu', langue: 'Zulu - isiZulu'}
      ]
    }
  },
  computed: {
    clientsData () {
      return this.$store.state.client.clients
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    villeData () {
      return this.$store.state.pays.villes
    },
    PaysData () {
      return this.$store.state.pays.pays
    },
    quartierData () {
      return this.$store.state.pays.quartiers
    }
  },
  methods: {
    NouveauClient () {
      if (this.IdClient === 'new_client') {
        this.isSidebarActiveLocal = true
        this.IdClient = null
      } else {
        const client = this.$store.getters['client/getClientById'](this.IdClient)
        this.NomClient = client ? client.Nom : ''
        this.PrenomsClient = client ? client.Prenoms : ''
        this.DateNaissanceClient = client ? client.DateNaiss : ''
        this.DomicileClient = client ? (client.Adresse ? client.Adresse.adresse : '') : ''
        this.ContactClient = client ? (client.Adresse ? client.Adresse.mobile : '') : ''
        this.ProfessionClient = client ? client.Profession : ''
      }
    },
    createClient () {

      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Civilite: this.Civilite,
        Nom: this.Nom,
        Prenoms: this.Prenoms,
        Alias: this.Alias,
        Profession: this.Profession,
        DateNaiss: this.DateNaiss,
        Activitextra: this.Activitextra,
        Langue: this.Langue,
        adresse: this.adresse_client,
        email: this.email,
        pays: this.pays_client,
        ville: this.ville,
        quartier: this.quartier,
        localite: this.localite,
        tel: this.tel,
        mobile: this.mobile,
        /* fax: this.fax_client,
        cp: this.cp, */
        remarque: this.remarque,
        CarteFidelite: this.CarteFidelite,
        Assure: this.Assure,
        DCD: this.DCD,
        /* ChefFamille: this.ChefFamille, */
        PasSMS: this.PasSMS,
        PasMail: this.PasMail

      }
      this.$vs.loading({
        type: 'sound'
      })

      /* setTimeout(() => {
        this.$vs.loading.close()
        this.$router.push('/apps/client/client-list')
      }, 1500) */


      this.$store.dispatch('client/addClient', payload)
        .then((resp) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdClient = resp.data._id
          this.popupActiveClient = false

          this.Civilite = ''
          this.Nom = ''
          this.Prenoms = ''
          this.Alias = ''
          this.Profession = ''
          this.DateNaiss = ''
          this.Activitextra = ''
          this.Langue = ''
          this.adresse_client = ''
          this.remarque = ''
          this.CarteFidelite = false
          this.Assure = false
          this.DCD = false
          this.ChefFamille = false
          this.PasSMS = false
          this.PasMail = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    activeUserInfo () {
      this.idOfficine = this.activeUserInfos.IdOfficine

      if (this.$store.state.client.clients.length === 0) {
        this.getClientsOfficine(this.idOfficine)
      }
    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .then(() => {
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getPays () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('pays/getPays')
        .then(() => { this.$vs.loading.close() })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
        })
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getQuartiers () {
      this.$store.dispatch('pays/getQuartiers')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  created () {
    this.activeUserInfo()
    if (!(this.$store.state.pays.pays.length > 0)) this.getPays()
  }
}
</script>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 700px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}

.customtable, .customth, .customtd {
  border: 0.5px solid black;
  border-collapse: collapse;
  /* font-family: 'Times New Roman'; */
  font-size: 12px;
}
</style>
